import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "../container"

const Sponsors = () => (
  <StaticQuery
    query={graphql`
      query {
        allStrapiSponsors {
          nodes {
            name
            id
            url
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1200
                    placeholder: DOMINANT_COLOR
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <div className="bg-white">
        <Container>
        <div className="py-12 px-4 sm:px-6 lg:px-8">
          <hr className="pb-12" />
          <p className="text-center text-base leading-6 font-semibold uppercase text-gray-600 tracking-wider">
            Unsere Sponsoren
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
          {data.allStrapiSponsors.nodes.map((sponsor) => (
            <div key={sponsor.id} className="flex justify-center py-8 px-8">
              <a target="_blank" rel="noopener noreferrer" href={sponsor.url}>
              <GatsbyImage
                  image={sponsor.logo.localFile.childImageSharp.gatsbyImageData}
                  className="h-full w-full object-cover"
                  alt={sponsor.name}
                  title={sponsor.name}
                />
              </a>
            </div>
          ))}
        </div>
        </Container>
      </div>
    )}
  />
);

export default Sponsors
