import React from "react";
import { Link } from "gatsby";
import ruebenLogo from "../../images/rueben-logo.svg";

const Hero = () => {
  return (
    <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-0 sm:px-6 md:mt-8 lg:mt-0 xl:mt-0 pb-0">
      <div className="">
        <div className="hero container max-w-screen-lg mx-auto pb-10 flex">
          <img
            className="h-auto sm:w-2/3 mx-auto md:w-2/3 lg:w-2/3"
            src={ruebenLogo}
            alt=""
          />
        </div>

        <div className="py-4">
          <p className="mt-3 max-w-md mx-auto text-base text-center text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Herzlich willkommen auf unserer Website
          </p>
        </div>
        <div className="my-6 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
            <Link
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-600 bg-white hover:text-gray-900 focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
              to={`/pages/verladeplaene`}
            >
              Verladepläne
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
