import React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Moment from "react-moment";
import "moment/locale/de"
import Markdown from "react-markdown";
import Container from "../components/container";
import Hero from "../components/hero/hero";
import Sponsors from "../components/sponsors/sponsors";

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;

    const { currentPage, numPages } = this.props.pageContext;
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString();
    const nextPage = (currentPage + 1).toString();

    return (
      <Layout>
        <Container>
          <Hero />
          <div className="px-4 py-6 sm:px-6 lg:px-8 lg:py-12">
            <div className="text-center">
              <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
                News
              </h2>
            </div>
            <div className="mt-12 grid gap-5 lg:grid-cols-3 lg:max-w-none">
              {data.posts.edges.map((post) => (
                <div
                  key={post.node.id}
                  className="flex flex-col rounded shadow overflow-hidden"
                >
                  <div className="flex-shrink-0">
                    <Link to={`/${post.node.slug}`}>
                      <GatsbyImage
                        image={post.node.image.localFile.childImageSharp.gatsbyImageData}
                        className="h-48 w-full object-cover"
                        alt="Rüebenumschlag Mittelland"
                      />
                    </Link>
                  </div>
                  
                  <div className="mb-2 flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <div className="text-sm font-medium text-gray-400 mb-2">
                        {post.node.categories.map((cat) => (
                          <Link
                            to={`/categories/${cat.slug}`}
                            className="a:hover: underline"
                          >
                            <span className="pr-3">{cat.category}</span>
                          </Link>
                        ))}
                      </div>

                      <Link
                        to={`/${post.node.slug}`}
                        style={{ display: `block` }}
                      >
                        <p className="text-2xl font-semibold text-gray-800">
                          {post.node.title}
                        </p>
                        <div className="mt-3 text-base text-gray-500">
                          <Markdown
                            source={post.node.excerpt}
                            escapeHtml={false}
                          />
                        </div>
                      </Link>
                    </div>
                    <div className="mt-6 flex items-center">
                      <div className="ml-0">
                        <div className="flex space-x-1 text-sm text-gray-500">
                          <span className="capitalize">
                            {post.node.author.name}
                          </span>
                          <span aria-hidden="true">&middot;</span>
                          <time datetime={post.node.published_at}>
                            <Moment format="DD MMMM YYYY">
                              {post.node.published_at}
                            </Moment>
                          </time>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="pt-12">
              <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
                <div className="-mt-px w-0 flex-1 flex">
                  {!isFirst && (
                    <a
                      href={prevPage}
                      className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                    >
                      <svg
                        className="mr-3 h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Zurück
                    </a>
                  )}
                </div>

                {/*
{!isFirst && (
                {/* <Link to={prevPage} rel="prev"
                className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                  ← Previous Page
              </Link>

              )} */}

                <div className="hidden md:-mt-px md:flex">
                  {Array.from({ length: numPages }, (_, i) => (
                    <Link
                      to={`/${i === 0 ? "" : i + 1}`}
                      key={`pagination-number${i + 1}`}
                      className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                      activeClassName="border-gray-500 text-gray-600"
                    >
                      {i + 1}
                    </Link>
                  ))}
                </div>
                <div className="-mt-px w-0 flex-1 flex justify-end">
                  {!isLast && (
                    <Link
                      to={`/${nextPage}`}
                      rel="next"
                      className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                    >
                      Weiter
                      <svg
                        className="ml-3 h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </Link>
                  )}
                </div>
              </nav>
            </div>
          </div>
        </Container>
        <Sponsors />
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    posts: allStrapiPost(
      sort: { order: DESC, fields: published_at }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          slug
          title
          excerpt
          body
          published_at
          updated_at
          image {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  width: 1200
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          categories {
            category
            slug
            id
          }
          author {
            name
            id
          }
        }
        previous {
          slug
        }
        next {
          slug
        }
      }
    }
  }
`;
